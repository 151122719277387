html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;

  min-height: 100%;
  overflow-x: hidden;

  -webkit-text-size-adjust: none;
}

#root {
  flex: 1;

  display: flex;
  flex-direction: column;
}

img, video {
  display: block;
  border: 0;
  outline: none;
}
